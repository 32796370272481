import { z } from 'zod';
import { DocumentStatusSchema } from './document-status';

const WithUserReadsSchema = z.object({
  isReadByUser: z.boolean(),
});

export const DocumentMetadataSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  status: DocumentStatusSchema,
  version: z.number(),
  fileNamePrefix: z.string(),
  updatedAt: z.string(),
});
export type DocumentMetadataDto = z.infer<typeof DocumentMetadataSchema>;

export const DocumentMetadataWithUserReadsSchema =
  DocumentMetadataSchema.merge(WithUserReadsSchema);
export type DocumentMetadataWithUserReadsDto = z.infer<
  typeof DocumentMetadataWithUserReadsSchema
>;

// ------------

export const DocumentSchema = DocumentMetadataSchema.merge(
  z.object({
    content: z.string().min(1),
  }),
);
export type DocumentDto = z.infer<typeof DocumentSchema>;

export const DocumentWithUserReadsSchema =
  DocumentSchema.merge(WithUserReadsSchema);
export type DocumentWithUserReadsDto = z.infer<
  typeof DocumentWithUserReadsSchema
>;
