import { ToxiHttpClient } from '@/api/toxicology-client';
import { FormkitSelectOption } from '@/formkit/types';
import {
  GetToxicologyEmployersResponseDto,
  GetToxicologyTreatmentCentersResponseDto,
  OrganizationType,
} from '@careos/organization-api-types';
import { SamplingLocation } from '@careos/toxicology-types';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

type ChosenSamplingLocationWorkplace = {
  employer: FormkitSelectOption;
  subdivision?: FormkitSelectOption;
  subcontractor?: string;
};

type ChosenSamplingLocationTreatmentCenter = {
  treatment_center: FormkitSelectOption;
};

export const useSamplingLocationStore = defineStore('sampling-location', () => {
  const route = useRoute();
  const samplingLocation = ref<SamplingLocation['samplingLocation']>();

  const selectedRequisitionType = ref<OrganizationType | null>(null);

  const formDataWorkplace = ref<ChosenSamplingLocationWorkplace>(
    {} as ChosenSamplingLocationWorkplace,
  );

  const formDataTc = ref<ChosenSamplingLocationTreatmentCenter>(
    {} as ChosenSamplingLocationTreatmentCenter,
  );

  const fetchEmployers = async () => {
    const res = await ToxiHttpClient.get<GetToxicologyEmployersResponseDto>(
      'organization-api-client/toxicology-get-employers',
    );
    return res.data;
  };

  const fetchTreatmentCenters = async () => {
    const res =
      await ToxiHttpClient.get<GetToxicologyTreatmentCentersResponseDto>(
        'organization-api-client/toxicology-get-treatment-centers',
      );
    return res.data;
  };

  const $reset = () => {
    samplingLocation.value = undefined;
    selectedRequisitionType.value = null;
    formDataWorkplace.value = {} as ChosenSamplingLocationWorkplace;
    formDataTc.value = {} as ChosenSamplingLocationTreatmentCenter;
  };

  const populateStoreWithEmployerSamplingLocation = () => {
    samplingLocation.value = {
      kind: 'workplace',
      display: formDataWorkplace.value.employer?.label as string,
      organizationId: formDataWorkplace.value.employer?.value as string,
      subdivision: formDataWorkplace.value.subdivision?.value
        ? {
            display: formDataWorkplace.value.subdivision?.label,
            organizationId: formDataWorkplace.value.subdivision?.value,
          }
        : undefined,
      subcontractor: formDataWorkplace.value.subcontractor?.trim() || undefined,
    };
  };
  const populateStoreWithTreatmentCenterSamplingLocation = () => {
    samplingLocation.value = {
      kind: 'treatment_center',
      display: formDataTc.value.treatment_center.label,
      organizationId: formDataTc.value.treatment_center.value,
    };
  };

  const populateStoreWithSamplingLocation = (
    selectedReqType: OrganizationType,
  ) =>
    selectedReqType === 'workplace'
      ? populateStoreWithEmployerSamplingLocation()
      : populateStoreWithTreatmentCenterSamplingLocation();

  // We want to empty the form data if the employer changes
  watch(
    () => formDataWorkplace.value.employer?.value,
    () => {
      formDataWorkplace.value.subdivision =
        {} as ChosenSamplingLocationWorkplace['subdivision'];
      formDataWorkplace.value.subcontractor = undefined;
    },
  );

  watch(
    () => route.path,
    (currentPath, previousPath) => {
      const employerPath = '/order/employer';
      const treatmentCenterPath = '/order/treatment-center';
      if (currentPath === employerPath) {
        selectedRequisitionType.value = 'workplace';
      } else if (currentPath === treatmentCenterPath) {
        selectedRequisitionType.value = 'treatment_center';
      }
      if (
        previousPath === treatmentCenterPath &&
        formDataTc.value?.treatment_center?.value
      ) {
        populateStoreWithSamplingLocation('treatment_center');
      } else if (previousPath === employerPath) {
        populateStoreWithSamplingLocation('workplace');
      }
    },
    { immediate: true },
  );

  return {
    selectedRequisitionType,
    samplingLocation,
    formDataWorkplace,
    formDataTc,
    populateStoreWithEmployerSamplingLocation,
    populateStoreWithTreatmentCenterSamplingLocation,
    fetchEmployers,
    fetchTreatmentCenters,
    $reset,
  };
});
