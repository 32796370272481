import { ToxiHttpClient } from '@/api/toxicology-client';
import {
  AddUserRequestDto,
  CheckUserForEditingRequestDto,
  CheckUserForEditingResponseDto,
  CheckUserInDatabaseRequestDto,
  CheckUserInDatabaseResponseDto,
  GetRolesResponseDto,
  GetUsersByOrganizationResponseDto,
  OrganizationType,
  RemoveUsersFromOrganizationRequestDto,
  ReSendUserWelcomeEmailRequestDto,
  ReSendUserWelcomeEmailResponseDto,
  ResetMfaRequestDto,
  ResetMfaResponseDto,
  UpdateUserNotificationRequestDto,
  UpdateUserNotificationResponseDto,
  UpdateUserRequestDto,
  UpdateUserResponseDto,
} from '@careos/organization-api-types';
import { defineStore } from 'pinia';
import { useOrganization } from './organization';

type ArrayElementType<T> = T extends (infer U)[] ? U : never;

export type UserFromResponseDto = ArrayElementType<
  GetUsersByOrganizationResponseDto['users']
>;

export interface UserViewState {
  users: GetUsersByOrganizationResponseDto['users'];
  roles: GetRolesResponseDto['roles'];
}

export const useUsersStore = defineStore('users-store', {
  state: (): UserViewState => ({
    users: [],
    roles: [],
  }),
  actions: {
    async fetchRoles(organizationType: OrganizationType) {
      const res = await ToxiHttpClient.get<GetRolesResponseDto>(
        `/organization-api-client/get-roles/${organizationType}`,
      );
      this.roles = res.data.roles;
    },

    async fetchUsers(
      organizationType: OrganizationType,
      organizationKey: string,
    ) {
      const orgStore = useOrganization();
      const res = await ToxiHttpClient.get<GetUsersByOrganizationResponseDto>(
        `/organization-api-client/get-users-by-organization/${organizationType}/${organizationKey}`,
      );
      this.users = res.data.users.reverse();
      orgStore.displayNames.employerDisplayName = '';
      orgStore.displayNames.organizationDisplayName = res.data.organizationName;
      orgStore.organizationLoginName = res.data.organizationLoginName;
    },

    async checkUser(dto: CheckUserInDatabaseRequestDto) {
      const res = await ToxiHttpClient.post<CheckUserInDatabaseResponseDto>(
        '/organization-api-client/check-user',
        dto,
      );
      return res.data;
    },
    async createUser(dto: AddUserRequestDto) {
      await ToxiHttpClient.post<AddUserRequestDto>(
        '/organization-api-client/add-user',
        dto,
      );
      await this.fetchUsers(dto.organizationType, dto.organizationKey);
    },
    async updateUser(dto: UpdateUserRequestDto) {
      const res = await ToxiHttpClient.patch<UpdateUserResponseDto>(
        '/organization-api-client/update-user',
        dto,
      );

      if (!res.data.doesEmailAlreadyExist) {
        await this.fetchUsers(dto.organizationType, dto.organizationKey);
      }
      return res.data;
    },
    async removeUserFromOrg(dto: RemoveUsersFromOrganizationRequestDto) {
      const res = await ToxiHttpClient.post(
        '/organization-api-client/remove-users-from-organization',
        dto,
      );

      await this.fetchUsers(dto.organizationType, dto.organizationKey);
      return res.data;
    },

    async checkUserForEditing(dto: CheckUserForEditingRequestDto) {
      const res = await ToxiHttpClient.post<CheckUserForEditingResponseDto>(
        '/organization-api-client/check-user-for-editing',
        dto,
      );

      return res.data;
    },

    async resetMfa(dto: ResetMfaRequestDto) {
      const res = await ToxiHttpClient.post<ResetMfaResponseDto>(
        '/organization-api-client/reset-mfa',
        dto,
      );

      return res.data;
    },

    async updateUserNotifications(dto: UpdateUserNotificationRequestDto) {
      const res = await ToxiHttpClient.patch<UpdateUserNotificationResponseDto>(
        '/organization-api-client/update-notifications',
        dto,
      );
      return res.data;
    },

    async resendWelcomeEmail(dto: ReSendUserWelcomeEmailRequestDto) {
      const res = await ToxiHttpClient.post<ReSendUserWelcomeEmailResponseDto>(
        '/organization-api-client/resend-welcome-email',
        dto,
      );
      return res.data;
    },
  },
});
